

<div class="has-footer">
  <footer class="home-footer row">
    <div class="left" [routerLink]="'/about'">About Hub</div>
    <div class="mid txt-c" [routerLink]="'/about'">
      <a href="https://wiki.ktown.team/en/tech-platform-for-good/hub/" class="link" target="_blank">
        <div class="img">
          <img src="/assets/brand/ktownteam_logo.png" alt="">
        </div>
      </a>
    </div>
    <div class="right txt-c" [routerLink]="'/about'">Sitemap</div>
  </footer>
</div>
