<div class="overlay">
  <div class="res">
    <div class="id">NEW</div>
    <div class="name">Resource</div>
    <form>
      <div class="general">
        <h4>General</h4>
        <input type="text" placeholder="Resource Name">
        <input type="text" placeholder="Resource Address (or type 'online')">
        <input type="text" placeholder="Resource Website (optional)">
        <textarea name="name" cols="80" placeholder="Resource Description"></textarea>
      </div>
      <div class="contact">
        <h4>Contact (for updates)</h4>
        <input type="text" placeholder="Resource Contact Name">
        <input type="text" placeholder="Resource Contact Email / Phone">
      </div>
      <!-- <div class="affilitation">
        <h4>Affilitation (optional)</h4>
        <div>
          <input type="radio" id="indiv" name="aff" value="indiv" checked>
          <label for="indiv">Individual</label>
        </div>
        <div>
          <input type="radio" id="resrep" name="aff" value="resrep">
          <label for="resrep">Resource representative</label>
        </div>
        <div>
          <input type="radio" id="org" name="aff" value="org">
          <label for="org">Organization representative</label>
        </div>
      </div> -->
    </form>
    <div class="actions">
      <div class="submit">Submit Resource</div>
      <div class="submit cancel" [routerLink]="'/'">Cancel</div>
    </div>
  </div>
</div>