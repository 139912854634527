import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.sass']
})
export class CategoriesComponent implements OnInit {

  constructor(
     private route: ActivatedRoute,
     private router: Router
  ) {}

  ngOnInit() {
  }

  @Input()
  limit:number;

  @Input()
  lang = 'es';

  categories: any = `
urgent
education
family
food
employment
fianance
health
mental health
housing
legal
training
immigration
`.trim().split('\n');

  categories_es: any = `
urgente
educación
familia
comida
fianance
empleo
salud
salud mental
alojamiento
legal
capacitación
inmigración
`.trim().split('\n');
  categories_ko: any = `
긴급한
교육
가족
음식
약혼자
고용
건강
정신 건강
주택
적법한
훈련
이주
`.trim().split('\n');
  categories_tl: any = `
kagyat
edukasyon
pamilya
pagkain
kasintahan
trabaho
kalusugan
kalusugang pangkaisipan
pabahay
ligal
pagsasanay
imigrasyon
`.trim().split('\n');

  // create a limit categories to create show more
  // add request category

  limitTo(a){
    return a;
  }


  open(c){
    this.router.navigate(['/search'], { queryParams: {q:"cat:"+c} });
  }


}
