import { Component, OnInit, Input } from '@angular/core';
import { ResourcesService } from "./resources.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.sass']
})

export class ResourcesComponent implements OnInit {

  @Input() category = '';
  @Input() lang = 'en';
  @Input() customResource = false;
  @Input() searchResults: any[] = [];
  @Input() resources: any[];
  @Input() type = '';
  @Input() limit: number = 8;

  wifi = false;
  details = true;

  localResources: any[] = [];

  constructor(
    private _resources: ResourcesService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (!this.getLocalResources()) {
      this.getResources();
    }
  }

  getResources(): void {
    if (!this.customResource) {
      this._resources.getResources().subscribe({
        next: (d: any[]) => this.setResources(d),
        error: (err: any) => console.error(err)
      });
    } else {
      this.setResources(this.searchResults);
    }
  }

  setResources(r: any[]): void {
    console.log({ r });
    this.resources = this.limit ? r.slice(0, this.limit) : r;
    this.setLocalResources(this.resources);
    this.resources.forEach(resource => {
      resource.desc = false;
    });
  }

  getLocalResources(): boolean {
    const name = this.getLocalStorageName();
    const localResources = JSON.parse(localStorage.getItem(name) || 'null');
    if (localResources) {
      this.setResources(localResources);
      return true;
    }
    return false;
  }

  setLocalResources(resources: any[]): void {
    const name = this.getLocalStorageName();
    localStorage.setItem(name, JSON.stringify(resources));
  }

  refreshResources(): void {
    const name = this.getLocalStorageName();
    localStorage.removeItem(name);
    this.resources = [];
    this.getResources();
  }

  toggleDesc(event: Event, r: any): void {
    if (!r.desc) {
      this.resources.forEach(resource => {
        resource.desc = false;
      });
      r.desc = true;
      event.preventDefault();
    } else {
      this.router.navigate(['/resource/', r._id]);
    }
  }

  private getLocalStorageName(): string {
    return `resources${this.category ? '-' + this.category : ''}`;
  }
}
