<app-nav [theme]="'white'"></app-nav>

<div class="lists checklist">
  <small class="id">aoj7ysd8yn78ysn</small>
  <h2>Checklist</h2>
  <div class="desc">This is a minor description of the checklist.</div>
  <app-resources [type]="'list'" [category]="'list'"></app-resources>
</div>

<div class="actions" *ngIf="action!=='print'">
  <div class="action" (click)="action='share'" *ngIf="action!=='share'">Share</div>
  <div class="action" (click)="action='print'" *ngIf="action!=='print'">Print</div>
  <div class="action" (click)="action='save'" *ngIf="action!=='save'">Save</div>
  <div class="action close" (click)="action=''" *ngIf="action!==''">Close</div>
</div>
<!-- Share -->
<div class="actions" *ngIf="action==='save'">
  <input type="text" placeholder="Email or phone to save checklist">
</div>
<!-- Print -->
<div class="actions" *ngIf="action==='share'">
  <input type="text" placeholder="Email or phone to share checklist">
</div>
<!-- Save -->
<div class="actions" *ngIf="action==='print'">
  <div class="action printlocation">Find locations to print</div>
  <div class="action close" (click)="action=''">Return</div>
</div>

<div class="actions">
  <div class="action create" *ngIf="action==='save'">Save</div>
  <div class="action create" *ngIf="action==='share'">Share</div>
  <div class="action create" *ngIf="action==='print'">Print</div>
</div>
