import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})

export class ResourceService {
  constructor(private http:HttpClient) {}

  private API = environment.api;

  getResource(id){
    return this.http.get(this.API + 'resource/'+ id);
  }

  // updateResource(id){
  //
  // }
  //
  // deleteResource(id){
  //
  // }

}
