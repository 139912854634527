import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ActivatedRoute, Router } from "@angular/router";

import { ResourcesService } from "../resources.service";

@Component({
  selector: 'app-bar',
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.sass']
})
export class BarComponent implements OnInit {

  constructor(
     private route: ActivatedRoute,
     private router: Router,
     private _resource: ResourcesService
  ) {}

  ngOnInit(): void {
  }

  @Input()
  lang = false;

  @Input()
  query = '';

  @Input()
  searchResults = [];


  @Output() newSearchEvent = new EventEmitter();

  langModal = false;
  selectLang = 'none';
  catLimit = 12;


  searchIt(q) {
    this.router.navigate(['/search'], { queryParams: {q:this.query} });
    this.send(q);

  }

  send(q){
    this.newSearchEvent.emit(q);
  }

  // search(q){
  //   this._resource.getSearch({q:q}).subscribe(
  //     d=>this.next(d),
  //     e=>this.err(e)
  //   )
  // }
  // next(d){
  //   this.searchResults = d;
  // }
  // err(e){
  //   console.log(e);
  //
  // }
}
