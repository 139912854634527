<app-nav [theme]="'white'"></app-nav>

<div class="home">
  <div class="overlay">
    <div class="home-banner">
      <h1 class="about">Contact</h1>
    </div>
  </div>
</div>

<div class="content contact">
  <h2>Direct</h2>
  <div>
    <a href="https://hi.ktown.team/hub">hi.ktown.team/hub</a>
  </div>
  <h2>Donate</h2>
  <div>
    <a href="https://givebutter.com/ktownteam">GiveButter</a>
  </div>
  <h2>Instagram</h2>
  <div>
    <a href="https://instagram.com/ktownteam">&#64;KtownTeam</a>
  </div>
  <h2>Threads</h2>
  <div>
    <a href="https://www.threads.net/@ktownteam">&#64;KtownTeam</a>
  </div>
  <h2>Twitter</h2>
  <div>
    <a href="https://twitter.com/ktownteam">&#64;KtownTeam</a>
  </div>
</div>
<app-footer></app-footer>
