<div class="resources">
  <a class="resource {{category}} {{type}} {{r.desc? 'desc': ''}}" *ngFor="let r of resources | slice:0:(limit ? limit : 8)" (click)="toggleDesc($event, r)">
    
  <!-- <a class="resource {{category}} {{type}}  {{r.desc? 'desc':''}}" *ngFor="let r of resources" (click)="toggleDesc(r)" [routerLink]="'/resource/'+r._id"> -->
    <ng-container *ngIf="category==='v2'">
      <div class="content">

        <div 
          class="img" 
          [style.background-image]="r.org.screen ? 
                                    'url(' + 'https://cdn.ktown.team/imgs/external/' + r.org.screen + ')' : 
                                    'url(/assets/brand/bg.png)'">

          
          
          <div class="overlay">
            <!-- Title -->
            <!-- {{r.desc? 'bold':'bold'}} -->
            <div class="org_name">
              {{r.org.short || r.org.name}}
              
            </div>
            <!-- <div class="org_location">
              {{r.locations.address || r.locations.zip}}
            </div> -->
            <div class="desc">
              {{r.resources.description}}
            </div>
          </div>
        </div>
        <div class="info">
          <div class="name">
            <div class="type">{{r.desc? r.org.name : r.resources.type}}</div>
            {{r.desc? "View Resource" : r.resources.title}}
          </div>
        </div>
      </div>
    </ng-container>
    <!-- Recent Category -->
    <ng-container *ngIf="category==='recent'">
      <div class="top">
        <div class="catg">{{r.org.type || ""}}</div>
      </div>
      <div class="img">
        <div class="name_long name_nowifi" *ngIf="!wifi">{{r.resources.title}}</div>
      </div>
      <div class="bottom">
        <div class="name_long" *ngIf="!wifi">{{r.org.name}}</div>
        <div class="name" *ngIf="wifi">{{r.org.name}}</div>
      </div>
    </ng-container>

    <!-- Featured, Empty or List Category -->
    <ng-container *ngIf="category==='featured' || category==='' || category==='list'">
      <div class="top">
        <div class="name">{{r.org.name}}</div>
        <div class="catg">{{r.resources.type}}</div>
      </div>
      <div class="img">
        <div class="name_long name_nowifi" *ngIf="!wifi">{{r.resources.title}}</div>
      </div>
      <div class="bottom">
        <div class="desc">{{r.resources.description}}</div>
        <div class="start">View Resource</div>
      </div>
    </ng-container>

    <!-- Orgs Category -->
    <ng-container *ngIf="category==='orgs'">
      <div class="top">
        <div class="name">{{r.org.name}}</div>
        <div class="catg">{{r.resources.type}}</div>
      </div>
      <div class="img">
        <div class="name_long name_nowifi" *ngIf="!wifi">{{r.resources.title}}</div>
      </div>
      <div class="bottom">
        <div class="desc">{{r.org.description}}</div>
        <div class="start">View</div>
      </div>
    </ng-container>

  </a>

  <a class="resource {{category}} reload" (click)="refreshResources()" *ngIf="!customResource">

    <ng-container>
      <div class="content">
        <div class="info">
          <div class="name">
            <div class="type">{{"View more resources"}}</div>
            {{"Refresh"}}
          </div>
        </div>
      </div>
    </ng-container>
  </a>
</div>
