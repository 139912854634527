<app-nav [theme]="'white'"></app-nav>
<div class="home">
  <div class="overlay">
    <div class="home-banner">
      <h1 class="about">About Hub</h1>
      <div class="content">
        <h2>Connecting Koreatown to Thrive</h2>
        <p>
          Hub began as a grassroots initiative, born from the vibrant streets of Koreatown, Los Angeles. What started as
          a simple idea to connect neighbors with local resources has blossomed into a comprehensive, community-driven
          platform that's changing how people access vital services.
        </p>

        <h2>Our Mission</h2>
        <p>
          At Hub, we believe that everyone deserves easy access to the resources they need to thrive. We're on a mission
          to break down barriers, bridge information gaps, and empower our community through knowledge and connection.
        </p>

        <h2>What We Do</h2>
        <p>
          Hub is more than just a resource directory—we're a dynamic, real-time network connecting Koreatown, Los
          Angeles, and beyond to essential services that power fulfilling lives. From urgent needs to long-term support,
          we've got you covered:
        </p>
        <ul>
          <li>Emergency Services</li>
          <li>Housing Assistance</li>
          <li>Healthcare Access</li>
          <li>Educational Opportunities</li>
          <li>Legal Aid</li>
          <li>Employment Resources</li>
          <li>And much more!</li>
        </ul>

        <h2>The Power of Community</h2>
        <p>
          Hub is built by and for the community. We leverage local knowledge, cutting-edge technology, and the power of
          collaboration to create a platform that truly serves our diverse neighborhood. Our open collaboration model
          means that anyone can contribute, ensuring our directory stays current and comprehensive.
        </p>

        <h2>Innovation for Equity</h2>
        <p>
          As a proud part of The Platform developed by Ktown Team, Hub stands at the forefront of building a more
          equitable and inclusive Koreatown. We harness AI technology to provide personalized recommendations, making it
          easier than ever to find exactly what you need.
        </p>

        <h2>Our Impact</h2>
        <p>
          Every day, Hub connects individuals with life-changing resources. We've helped families find food assistance,
          connected job seekers with employment opportunities, and guided newcomers to essential services. These aren't
          just statistics—they're real stories of community members finding the support they need to overcome challenges
          and pursue their dreams.
        </p>

        <h2>Join the Hub Movement</h2>
        <p>Be part of our mission to create a more connected, resilient Koreatown:</p>
        <ol>
          <li><strong>Explore:</strong> Discover resources tailored to your needs at <a
              href="http://hub.ktown.team">hub.ktown.team</a>.</li>
          <li><strong>Share:</strong> Spread the word and help others connect to vital services.</li>
          <li><strong>Contribute:</strong> Volunteer your skills or knowledge to enhance our platform.</li>
          <li><strong>Attend:</strong> Join our monthly community meetups to learn, share, and grow together.</li>
        </ol>

        <h2>Looking Ahead</h2>
        <p>
          While our roots are in Koreatown, our vision extends beyond. We're excited to bring Hub's innovative approach
          to resource-sharing to communities across Los Angeles and beyond. Together, we're building a future where
          everyone has the tools they need to write their own success story.
        </p>

        <h2>Get in Touch</h2>
        <p>
          Have questions or want to get involved? We'd love to hear from you!
        </p>
        <ul>
          <li>Email: <a href="mailto:hub@ktown.team">hub&#64;ktown.team</a></li>
          <li>Follow us: <a href="https://links.ktown.team">&#64;KtownTeam</a> on social media</li>
        </ul>

        <p>
          Join us in our journey to create a more connected, empowered, and thriving community. At Hub, we believe that
          when we come together, amazing things happen. Let's make them happen together!
        </p>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>