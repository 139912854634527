<app-nav [theme]="'white'"></app-nav>

<div class="lists">
  <small>Tap & Hold Reorder</small>
  <h2>Checklists</h2>
  <small>Select & Remove to Delete</small>
  <!-- <div class="edit">Delete</div> -->
  <div class="search-container">
    <input type="text" placeholder="Search lists..." [(ngModel)]="search" (keyup.enter)="searchIt(query)">
    <div class="search" (click)="searchIt(query)"><i class="fa fal fa-search"></i></div>
  </div>
  <div class="contain">
    <!-- <div class="list" *ngFor="let l of checklists | filter:search" (click)="openList(l)">
      <div class="name">{{l}}</div>
      <div class="details">{{l}}</div>
    </div> -->
  </div>
  <div class="create" (click)="create=!create">Create New Checklist</div>
</div>


<div class="createItem" *ngIf="create">
  <form>
    <h2>Create Checklist</h2>
    <div class="lists" style="height: auto;min-height:auto">
      <div class="list">
        <div class="name">{{cName || "Name"}}</div>
        <div class="details">{{cDesc || "Description of items within checklist"}}</div>
      </div>
    </div>
    <input type="text" placeholder="Name" [(ngModel)]="cName" [ngModelOptions]="{standalone: true}">
    <textarea name="name" rows="8" cols="80" placeholder="Description of checklist" [(ngModel)]="cDesc" [ngModelOptions]="{standalone: true}"></textarea>
    <button type="button" class="create" (click)="createNew()" class="{{cName? 'fill':'empty'}}">Create new checklist</button>
  </form>
  <button type="button" class="cancel" (click)="create=!create" class="{{cName? 'fill':'empty'}}">Cancel</button>
</div>

<div class="overlayLists" (click)="create=false" *ngIf="create">
</div>
