import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ChecklistService } from "../checklist.service";

@Component({
  selector: 'app-lists-modal',
  templateUrl: './lists-modal.component.html',
  styleUrls: ['./lists-modal.component.sass']
})
export class ListsModalComponent implements OnInit {

  constructor(
    private _list: ChecklistService
  ) { }

  ngOnInit(): void {
    this.checkIndex();
  }

  @Input()
  checklist = false;
  @Input()
  id = "";
  @Input()
  name = "";

  @Output() close = new EventEmitter();

  closeModal(){
    this.close.emit(null);
  }




  listQuery = '';
  listQueryOld = '';

  newListMode = false;
  newListName = 'Demo';

  checklists = [];
  checklistsDemo = [
  ]

  err(e){
    console.log(e);
  }

  check(id, list){
  }

  match(id, list){
    return this._list.match(id, list);
  }


  checkIndex(){
    let index = this._list.checkIndex();
    this.updateChecklists(index);
  }

  updateChecklists(index){
    this.checklists = index;
  }

  toggle(id, list){
    this._list.toggle(id, list);
    // this.checkIndex();
  }

  toggleList(name, list){
    this._list.toggleList(name,list);
  }

  createNewList(){
    this.newListMode = true;
    this.listQueryOld = this.listQuery;
    this.listQuery = '        ';
  }

  saveNewList(){
    if(this.newListName !== ''){ // Check for dupe next
      this.newListMode = false;
      this.listQuery = '';
      this.listQuery = this.listQueryOld;
      this.toggleList(this.newListName, 'index');
      this.checkIndex();
    }

  }


}
