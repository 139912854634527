<app-nav [theme]="'white'"></app-nav>

<div class="home">
  <div class="overlay">
    <div class="home-banner">
      <app-bar [query]="q" [searchResults]="searchResults" (newSearchEvent)="search($event)"></app-bar>
    </div>
  </div>
</div>

<app-sub></app-sub>
<app-resources [customResource]="'true'" [category]="'v2'" [searchResults]="results" [(resources)]="results"></app-resources>
<app-footer></app-footer>
