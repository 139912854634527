<div class="home-nav {{theme==='white' && !menu?'blue':'white'}}">

  <div class="title" [routerLink]="'/'"><i class="fas fa-language"></i> EN</div>
  <div class="menu" (click)="menu=!menu"><i class="fas fa-2x fa-{{menu?'times':'bars'}}"></i></div>
</div>

<div class="fullMenu " [hidden]="!menu">
  <!-- English -->
  <img class="home-logo" src="/assets/brand/hub_logo.png" alt="72 dots in three rings">
  <span [hidden]="!(lang==='en')">
    <!-- <div i18n [routerLink]="'/resource/new'" (click)="menu=!menu">Add</div> -->
    <div i18n [routerLink]="'/search'" (click)="menu=!menu">Search</div>
    <div i18n [routerLink]="'/checklists'" (click)="menu=!menu">Checklists</div>
    <div i18n [routerLink]="'/about'" (click)="menu=!menu">About</div>
    <div i18n [routerLink]="'/contact'" (click)="menu=!menu">Contact</div>
    -
    <div i18n (click)="menu=!menu">Close Menu</div>
  </span>
  <!-- Spanish -->
  <span [hidden]="!(lang==='es')">
    <div i18n>Agregar</div>
    <div i18n>Buscar</div>
    <div i18n>Listas</div>
    <div i18n>Acerca de</div>
    <div i18n>Contacto</div>
  </span>
  <!-- Korean -->
  <span [hidden]="!(lang==='ko')">
    <div i18n>더하다</div>
    <div i18n>검색</div>
    <div i18n>체크리스트</div>
    <div i18n>약</div>
    <div i18n>접촉</div>
  </span>
  <!-- Tagalog -->
  <span [hidden]="!(lang==='tl')">
    <div i18n>Idagdag pa</div>
    <div i18n>Maghanap</div>
    <div i18n>Mga checklist</div>
    <div i18n>Tungkol sa</div>
    <div i18n>Makipag-ugnay</div>
  </span>

</div>
