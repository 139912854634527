import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from "@angular/router";

import { ResourceService } from "./resource.service";

import { ChecklistService } from "../checklist/checklist.service";

import { ClipboardService } from 'ngx-clipboard'


@Component({
  selector: 'app-resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.sass']
})
export class ResourceComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _res: ResourceService,
    private _list: ChecklistService,
    private _clip: ClipboardService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.getRes(this.id);
    });

  }

  start = false;
  res = {_id:""};
  id="";

  checklist:boolean = false;
  isShareSupported = navigator.share !== undefined;

  getRes(id){
    this._res.getResource(id).subscribe(
      d=>this.setRes(d),
      err=>this.err(err)
    )
  }

  setRes(r){
    this.res = r;
  }

  err(e){
    console.log(e);
  }


  copyLink(){
    this._clip.copy(location.href);
  }

  checkLink(link){
    // this.router.navigate(['/'])
    if(link){
      window.location.href = link
    }
  }

  share() {
    console.log("Share Clicked")
    if (this.isShareSupported) {
      navigator.share({
        title: 'Title',
        text: 'Text',
        url: window.location.href,
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
    }
  }

  async shareContent() {
    await navigator.share({
      title: 'Hello World',
      text: 'Check this out!',
      url: 'https://example.com',
    });
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Hello World',
          text: 'Check this out!',
          url: 'https://example.com',
        });
        console.log('Shared successfully');
      } catch (err) {
        console.error('Error while sharing:', err);
      }
    } else {
      // Fallback to your own sharing method
    }
  }

  onOverlayClick(){
    this.router.navigate(['/'])
  }

}
