import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.sass']
})
export class NavComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  @Input()
  lang = 'en';
  @Input()
  theme = '';
  menu = false;

}
