<div class="overlay" (click)="onOverlayClick()">
  <div class="res" *ngIf="res._id" (click)="$event.stopPropagation()">
    <div class="nav">
      <div class="prev"></div>
      <div class="next" (click)="onOverlayClick()"><i class="fas fa-times"></i></div>
    </div>
    <!-- <div class="id">{{res._id}}</div> -->
    <div class="img">
      
      <img src="{{res.resources.data?.META?.msapplication-TileImage? res.resources.data?.META?.msapplication-TileImage : '/assets/brand/ktownteam_logo.png' }}" alt="">
    </div>
    
    

    
    <div class="res_name">
      <div class="name">{{res.resources.title}}</div>
    </div>
    <div class="name_long">
      <b>{{res.org.type}}</b>
    </div>
    <p class="details" *ngIf="!start">
      {{res.resources.description || "hello"}}
    </p>
    <div class="name_long">
      {{res.org.name}}
    </div>
    <p class="org_desc">
      {{res.org.description}}
    </p>
    <!-- <div class="web">{{res.resources.link}}</div> -->
    <div class="actions share-resource">
      <div class="action copy-link" (click)="copyLink()">Copy Resource Link <i class="far fa-copy fa-fw"></i></div>
      <div class="action share" (click)="shareContent()"><i class="fas fa-share-alt"></i></div>
    </div>

    <div class="actions actions-extra">
      <!-- <div class="action" (click)="start=!start">Start</div> -->
      <div class="action" (click)="checkLink(res.resources.link)">Start</div>
      <div class="action" (click)="checklist=true">Add to Checklist</div>
      <!-- <div class="action" (click)="addToList(res._id)">Add to Checklist</div> -->
      <!-- <div class="action close" (click)="removeFromList(res._id)">Remove from Checklist</div> -->
    </div>
    <app-lists-modal *ngIf="checklist" (close)="checklist = false" [id]="res._id" [active]="checklist" [name]="res.name"></app-lists-modal>


  </div>

</div>