import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})

export class ResourcesService {
  constructor(private http:HttpClient) {}

  private API = environment.api;

  getSearch(q){
    return this.http.post(this.API + 'search',q);
  }

}
