import { Component, OnInit } from '@angular/core';

import { ChecklistService } from "../checklist/checklist.service";

@Component({
  selector: 'app-checklists',
  templateUrl: './checklists.component.html',
  styleUrls: ['./checklists.component.sass']
})
export class ChecklistsComponent implements OnInit {

  constructor(
    private _lists: ChecklistService
  ) { }

  ngOnInit() {
    this.checkIndex();
  }

  create = false;
  search = '';
  query = '';
  cName = '';
  cDesc = '';

  checklists = [];



  createNew(){
    this.create = false;
    this._lists.create(this.cName);
    this.checkIndex();
  }

  checkIndex(){
    let list = this._lists.checkIndex();
    this.checklists = list;
  }

  searchIt(q){

  }

  openList(l){
    console.log(l);
    console.log(localStorage.getItem("hubList-"+l));
    let id = this._lists.viewID(l);
    this.openID(id);
  }

  openID(id){
    // console.log(id);
  }

  /*
  Check ID
  View ID Index
    a. open ID
  Create new ID
    a. create ID
    b. assign ID
    c. save ID to index
    d. open ID
  */


}
