import { Injectable } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';


@Injectable({
  providedIn: 'root'
})
export class ChecklistService {

  constructor(
    private _cookie: CookieService
  ) { }

  add(id, list) {
    let newList: any = this.getList(list);
    newList.push({
      _id: id,
      name: "",
      desc: ""
    });
    this.save(list, newList);
  }

  remove(id, list) {
    // console.log("removing ID: " + id + " to " + list);
  }

  save(list, data) {
    if (data) {
      this.setList(list, this.toString(data));
    }
  }

  match(id, list) {
    let data = this.getList(list);
    let index = this.findIndex(data, id);
    if(index !== -1){
      return true;
    }else if (index === -1){
      return false
    }else{
      return false;
    }
  }

  getLists() {
    let list = this.getList('index');
    return list;
  }

  getList(list) {
    let selected = localStorage.getItem("hubList-" + list)
    if (selected) {
      return this.toObj(selected);
    } else {
      return []
    }
  }

  setList(list, data) {
    localStorage.setItem("hubList-" + list, data);
  }

  delList(list) {
    localStorage.removeItem("hubList-" + list);
  }

  create(list) {
    this.setList(list, '[]');
    if (list !== 'index') {
      this.addIndex(list);
    }
  }

  toString(o) {
    return JSON.stringify(o);
  }

  toObj(s) {
    return JSON.parse(s);
  }

  toggle(item, list) {
    let data = this.getList(list);
    let i = this.findIndex(data, item);
    if (i === -1) {
      this.addItem(item, list);
    } else if (i !== -1) {
      this.removeItem(item, list, i);
    } else {

    }
  }

  addItem(item, list) {
    let newList: any = this.getList(list);
    newList.push(item);
    this.save(list, newList);
  }
  removeItem(item, list, i) {
    let newList: any = this.getList(list);
    newList.splice(i, 1);
    this.save(list, newList);
  }

  addIndex(list) {
    let newList: any = this.getList('index');
    newList.unshift(list);
    this.save('index', newList);
  }

  delIndex(list) {
    let newList: any = this.getList('index');
    let i;
    if (newList) {
      i = this.findIndex(newList, list);
      if (i !== -1) {
        newList.splice(i, 1);
        this.delList(list);
        this.save('index', newList);
      }
    }
  }

  findIndex(arr, match) {
    return arr.indexOf(match);
  }

  checkIndex() {
    let i = localStorage.getItem('hubList-index');
    if (i) {
      return this.toObj(i);
    } else {
      this.create('index');
      this.create('default');
      return ['default'];
    }
  }

  toggleList(name, list) {
    let lists = this.checkIndex();
    let i = this.findIndex(lists, name);
    if (i === -1) {
      this.addIndex(name);
    } else if (i !== -1) {
      // this.delIndex(name);
      // toggle only for development
      // type to delete required;
      console.log('maybe dupe')
    } else {
      console.log('maybe dupe')
    }
  }

  viewID(id){
    console.log(id);

    let lists = this.getList("ID-index");
    if(lists.length === 0){
      return "no id";
    }
    let index = this.checkIDindex(id);
    if(index){
      this.goToID(id);
    }else{
      return "no id";
    }
  }

  checkIDindex(id){
    return true;
  }

  goToID(id){

  }


  admin() {

  }
}
