import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Components
import { ResourceComponent } from './resource/resource.component';
import { ResourcesComponent } from './resources/resources.component';
import { CategoriesComponent } from './categories/categories.component';
import { SearchComponent } from './search/search.component';
import { ChecklistComponent } from './checklist/checklist.component';
import { ChecklistsComponent } from './checklists/checklists.component';
import { EmailComponent } from './email/email.component';
import { BarComponent } from './search/bar/bar.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './templates/header/header.component';
import { FooterComponent } from './templates/footer/footer.component';
import { EsComponent } from './home/es/es.component';
import { TlComponent } from './home/tl/tl.component';
import { KoComponent } from './home/ko/ko.component';
import { TranslatePipe } from './core/translate.pipe';
import { NavComponent } from './templates/nav/nav.component';
import { SubComponent } from './categories/sub/sub.component';
import { AboutComponent } from './home/about/about.component';
import { ContactComponent } from './home/contact/contact.component';
import { NewComponent } from './resource/new/new.component';
import { OrgsComponent } from './orgs/orgs.component';

// Services
// import { ResourcesService } from "./resources/resource.service"


// import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { CookieService } from 'ngx-cookie-service';

// import { ClipboardModule } from 'ngx-clipboard';
import { ListsModalComponent } from './checklist/lists-modal/lists-modal.component';


@NgModule({ declarations: [
        AppComponent,
        ResourceComponent,
        ResourcesComponent,
        CategoriesComponent,
        SearchComponent,
        ChecklistComponent,
        ChecklistsComponent,
        EmailComponent,
        BarComponent,
        HomeComponent,
        HeaderComponent,
        FooterComponent,
        EsComponent,
        TlComponent,
        KoComponent,
        TranslatePipe,
        NavComponent,
        SubComponent,
        AboutComponent,
        ContactComponent,
        NewComponent,
        OrgsComponent,
        ListsModalComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        FormsModule,
        // Ng2SearchPipeModule,
        // ClipboardModule
    ], providers: [
        CookieService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
