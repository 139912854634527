import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sub',
  templateUrl: './sub.component.html',
  styleUrls: ['./sub.component.sass']
})
export class SubComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
