<div class="modal-checklist" *ngIf="id">
  <h3>Click to add or remove from list</h3>

  <h4>{{name}}</h4>
  <h5>{{id}}</h5>

  <div class="close" (click)="closeModal()">Close <i class="fas fa-times"></i></div>
  <input type="text" placeholder="Search checklists" [(ngModel)]="listQuery">
  <div class="lists createNew" *ngIf="newListMode">
    <div class="list">
      <input type="text" [(ngModel)]="newListName" placeholder="Name your checklist">
    </div>
  </div>
  <div class="lists">
    <div class="list {{match(id, list)? 'inlist':''}}" (click)="toggle(id, list)" *ngFor="let list of checklists | filter: listQuery">{{list}}</div>
  </div>
  <div class="create" (click)="createNewList()" *ngIf="!newListMode">Create new checklist</div>

  <div class="create" (click)="saveNewList()" *ngIf="newListMode">Save checklist</div>
</div>
