<app-header></app-header>
<h2>How it Works</h2>
<div class="how-it-works">
    <div class="step">
        <div class="number">1</div>
        <div class="description">
            <h3>Search or Browse Categories</h3>
            <p>Use the search bar for keywords or browse main categories. Featured Categories provide quick access to high-demand resources.</p>
        </div>
    </div>
    <div class="step">
        <div class="number">2</div>
        <div class="description">
            <h3>Find Relevant Resources</h3>
            <p>View relevant resources with details and links. Use filters to refine results. For personalized recommendations, click "Chat with HAI".</p>
        </div>
    </div>
    <div class="step">
        <div class="number">3</div>
        <div class="description">
            <h3>Connect with Services</h3>
            <p>Click "Learn More" or "Access Service" to access provider websites or services. Contact providers directly or use Ktown Hub for scheduling when available. For assistance, use "Feedback".</p>
        </div>
    </div>
</div>
<h2>Community Impact</h2>
<div class="community-impact">
    <div class="impact-stats">
        <div class="stat">
            <h3>10,000+</h3>
            <p>Community Members Served</p>
        </div>
        <div class="stat">
            <h3>500+</h3>
            <p>Resources Available</p>
        </div>
        <div class="stat">
            <h3>50+</h3>
            <p>Partner Organizations</p>
        </div>
    </div>
</div>
<h2>Featured</h2>
<app-resources [category]="'v2'" [limit]="5"></app-resources>
<h2>Resources</h2>
<app-resources [category]="'v2'" [limit]="11"></app-resources>
<h2>Essential Community Services</h2>
<app-resources [category]="'v2'" [limit]="2"></app-resources>
<h2>Health and Family Support</h2>
<app-resources [category]="'v2'" [limit]="2"></app-resources>
<h2>Education and Career Resources</h2>
<app-resources [category]="'v2'" [limit]="2"></app-resources>
<app-footer></app-footer>

<router-outlet>