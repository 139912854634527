import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-es',
  templateUrl: './es.component.html',
  styleUrls: ['./es.component.sass']
})
export class EsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  catLimit = 6;
}
