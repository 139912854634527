<app-nav></app-nav>
<div class="home home-hero hero" >
  <div class="overlay">
    <div class="home-banner">
      <img class="home-logo" src="/assets/brand/hub_logo.png" alt="72 dots in three rings">
      <h1 class="home-title" i18n [hidden]="lang!=='en'">Your Neighborhood,<br>Your Resources</h1>
      <div class="home-slogan" i18n [hidden]="lang!=='en'">Explore Koreatown's network of support services. Whether it's food,
        housing, healthcare, or education - we've got you covered.</div>

      <h1 class="home-title" i18n [hidden]="lang!=='es'">Tu Vecindario,<br>Tus Recursos</h1>
      <div class="home-slogan" i18n [hidden]="lang!=='es'">Explora la red de servicios de apoyo de Koreatown. Ya sea comida,
        vivienda, atención médica o educación - te tenemos cubierto.</div>

      <h1 class="home-title" i18n [hidden]="lang!=='ko'">당신의 이웃,<br>당신의 자원</h1>
      <div class="home-slogan" i18n [hidden]="lang!=='ko'">코리아타운의 지원 서비스 네트워크를 탐색하세요. 음식, 주거, 의료 또는 교육 - 우리가 당신을 도와드리겠습니다.</div>

      <h1 class="home-title" i18n [hidden]="lang!=='tl'">Ang Iyong Kapitbahayan,<br>Ang Iyong mga Mapagkukunan</h1>
      <div class="home-slogan" i18n [hidden]="lang!=='tl'">Tuklasin ang network ng mga serbisyong suporta ng Koreatown. Maging ito ay pagkain,
        pabahay, pangangalagang pangkalusugan, o edukasyon - saklaw ka namin.</div>
    <app-bar [lang]="true"></app-bar>
  </div>

  <div class="curation">
    <div class="item">
      <div class="title">Fire Prevention Week</div>
      <div class="description">Daily safety tips to prevent fires in your home.</div>
      <div class="open">Open</div>
    </div>
    <div class="item">
      <div class="title">Breast Cancer Awareness</div>
      <div class="description">Local screenings events and resources.</div>
      <div class="open">Open</div>
    </div>
    <div class="item">
      <div class="title">Domestic Violence Awareness</div>
      <div class="description">Local support services and hotlines.</div>
      <div class="open">Open</div>
    </div>
    <div class="item">
      <div class="title">Medicare Open Enrollment</div>
      <div class="description">Tips and resources to help you enroll in Medicare.</div>
      <div class="open">Open</div>
    </div>
    <div class="item">
      <div class="title">COVID-19 Resources</div>
      <div class="description">Local testing sites and resources.</div>
      <div class="open">Open</div>
    </div>
  </div>

  <!-- <app-resources [category]="'featured'"></app-resources> -->
</div>
