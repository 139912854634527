import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';

import { ResourceComponent } from './resource/resource.component';
import { NewComponent } from './resource/new/new.component';
import { ResourcesComponent } from './resources/resources.component';
import { CategoriesComponent } from './categories/categories.component';
import { SearchComponent } from './search/search.component';
import { ChecklistComponent } from './checklist/checklist.component';
import { ChecklistsComponent } from './checklists/checklists.component';
import { EmailComponent } from './email/email.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './home/about/about.component';
import { ContactComponent } from './home/contact/contact.component';

import { EsComponent } from './home/es/es.component';
import { KoComponent } from './home/ko/ko.component';
import { TlComponent } from './home/tl/tl.component';

const routes: Routes = [
  {
    path:'',
    component: HomeComponent,
    children:[
      {
        path:'resource/new',
        pathMatch: 'full',
        component: NewComponent
      },
      {
        path:'resource/:id',
        component: ResourceComponent
      }
    ]
  },
  {
    path:'about',
    component: AboutComponent
  },
  {
    path:'contact',
    component: ContactComponent
  },
  {
    path:'es',
    component: EsComponent
  },
  {
    path:'ko',
    component: KoComponent
  },
  {
    path:'tl',
    component: TlComponent
  },
  {
    path:'checklists',
    component: ChecklistsComponent
  },
  {
    path:'checklist/:id',
    component: ChecklistComponent
  },
  // {
  //   path:'checklist/:id/email',
  //   component: EmailComponent
  // },
  // {
  //   path:'categories',
  //   component: ChecklistComponent
  // },
  {
    path:'search',
    component: SearchComponent
  },
  {
    path:'search/:query',
    component: SearchComponent
  },
  {
    path:'**',
    redirectTo:''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
