import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router} from "@angular/router";

import { ResourcesService } from "./resources.service";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.sass']
})
export class SearchComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private _resources: ResourcesService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.q = params['q'];

      if(this.q !== '' && (this.results.length == 0)){
        this.search(this.q);
      }
    });

    window.scrollTo(0, 0);
  }

  q = '';

  searchResults = '';
  results = [];


  search(q){
    this._resources.getSearch({q:q}).subscribe(
      d=>this.next(d),
      e=>this.err(e)
    )
  }
  next(d){
    this.results = d;
    console.log(d);
  }
  err(e){
    console.log(e);

  }

}
